import * as React from "react"
import { Button, Tabs, Tab, Row, Col } from 'react-bootstrap'
import $ from 'jquery';
import { Link } from "gatsby";
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import Notification from "@components/notification/Notification"
import Seo from "@components/seo"
import "../../styles/index.scss"

// Menu collape
const menuClick = (e) => {
  $("body").toggleClass("dashboard-menu-open");
}
// Menu collape

const ReportsPage = (props) => (
  <>
    <Seo title="Resiland" />
    <Seo title="Resiland" />
    <div className="main-wrapper">
      <Sidebar menuActive="reports" />
      
      <div className="main-content">
        <div className="page-content">
          <header className="d-flex justify-content-between logout-wrapper">
            <a href="javascrip:void(0)" onClick={menuClick} className="d-lg-none d-md-inline-block"><i className="icon icon-dashboard-menu"></i></a>
            <h2 className="d-lg-block d-none">Reports</h2>
            <ul className="list-inline">
                <li className="list-inline-item">
                    <Notification />
                </li>
                <li className="list-inline-item">
                    <a href="#"><i className="icon icon-logout"></i></a>
                </li>
            </ul>
          </header>
          <h2 className="d-lg-none d-block mb-3">Reports</h2>

          <Row className="mt-3">
            <Col lg={12}>
              <ul className="list-inline m-0 reports-wrapper">
                <li className="list-inline-item me-5">Properties Overview Report</li>
                <li className="list-inline-item me-5">
                  <Link to={''}><i className="icon icon-download me-1"></i> Download Report</Link>
                </li>
                <li className="list-inline-item">
                  <Link to={''}><i className="icon icon-view me-1"></i> View Report</Link>
                </li>
              </ul>
            </Col>
            <Col lg={12}>
              <ul className="list-inline m-0 reports-wrapper">
                <li className="list-inline-item me-5">Agents Contact Report</li>
                <li className="list-inline-item ms-3 me-5">
                  <Link to={''}><i className="icon icon-download me-1"></i> Download Report</Link>
                </li>
                <li className="list-inline-item">
                  <Link to={''}><i className="icon icon-view me-1"></i> View Report</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>

    </div>
  </>
)

export default ReportsPage
