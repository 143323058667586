import React, { Component, useEffect, useState } from "react"
import $ from 'jquery';
import './assets/styles/_index.scss';

const Notification = (props) => {
    // Notification click
    const notificationClick = (e) => {
        $('.notification').toggleClass('notifyOpen');
    }
    // Notification click
    
    return (
        <>
            <div className="notification">
                <a href="#" className="notificaitonBell" onClick={notificationClick}><i className="icon icon-bell"><span className="d-flex align-items-center justify-content-center">10</span></i></a>
                <div className="notificationCard">
                    <h6>3 Notifications</h6>
                    <div className="notifyAlert red">High Alert</div>
                    <div className="notifyCategory">
                        <div className="d-flex justify-content-between">
                            <div className="font-weight-bold"><span className="notifyStatus red me-3"></span>
                                Category</div>
                            <div className="notifySeconds">15 seconds ago</div>
                        </div>
                        <p className="ps-4 pt-1 mb-0 pb-0 notify-msg">Lorem ipsum dolar</p>
                        <div className="d-flex justify-content-start ps-4 pt-2">
                            <div className="pe-5">
                                <p className="mb-0 pb-0 font-weight-bold">Name</p>
                                <p className="mb-0 pb-0 notify-msg">Jenifer</p>
                            </div>
                            <div>
                                <p className="mb-0 pb-0 font-weight-bold">Contact</p>
                                <p className="mb-0 pb-0 notify-msg">ID12134AB</p>
                            </div>
                        </div>
                    </div>
                    <div className="notifyBorderLine"></div>
                    <div className="notifyCategory">
                        <div className="d-flex justify-content-between">
                            <div className="font-weight-bold"><span className="notifyStatus red me-3"></span>
                                Category</div>
                            <div className="notifySeconds">2 minutes ago</div>
                        </div>
                        <p className="ps-4 pt-1 mb-0 pb-0 notify-msg">Lorem ipsum</p>
                        <div className="d-flex justify-content-start ps-4 pt-2">
                            <div className="pe-5">
                                <p className="mb-0 pb-0 font-weight-bold">Name</p>
                                <p className="mb-0 pb-0 notify-msg">Jeffery Archer</p>
                            </div>
                            <div>
                                <p className="mb-0 pb-0 font-weight-bold">Contact</p>
                                <p className="mb-0 pb-0 notify-msg">ID34587CF</p>
                            </div>
                        </div>
                    </div>
                    <div className="notifyAlert warning">Medium Alert</div>
                    <div className="notifyCategory">
                        <div className="d-flex justify-content-between">
                            <div className="font-weight-bold"><span className="notifyStatus warning me-3"></span>
                                Category</div>
                            <div className="notifySeconds">12 minutes ago</div>
                        </div>
                        <p className="ps-4 pt-1 mb-0 pb-0 notify-msg">Donec nec justo eget felis</p>
                        <div className="d-flex justify-content-start ps-4 pt-2">
                            <div className="pe-5">
                                <p className="mb-0 pb-0 font-weight-bold">Name</p>
                                <p className="mb-0 pb-0 notify-msg">Daniel</p>
                            </div>
                            <div>
                                <p className="mb-0 pb-0 font-weight-bold">Contact</p>
                                <p className="mb-0 pb-0 notify-msg">ID25386GR</p>
                            </div>
                        </div>
                    </div>
                    <div className="notifyCategory">
                        <p className="text-end mb-0 pb-0"><a href="#" className="notifySeconds">View
                                alarm history</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notification